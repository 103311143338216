import React, { useState } from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'

import dawidImage from '../../assets/image/jpeg/dawid.jpg'
import { Title, Section, Box, Text, List } from '../../components/Core'

const SectionStyled = styled(Section)``

const About = () => {
  const [currentCard, setCard] = useState('hide')

  const toggleCard = cardName => () =>
    setCard(currentCard === cardName ? 'hide' : cardName)

  return (
    <>
      {/* <!-- Content section --> */}
      <SectionStyled bg="dark" id="about">
        <Container>
          <Row className="justify-content-center mb-2">
            <Col lg="10">
              <div className="text-center">
                <Title color="light" id="oferta">
                  O nas
                </Title>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <div className="text-center">
              <Title variant="small" color="light" id="oferta">
                David Odrakiewicz – Blackcode CEO
              </Title>
            </div>
          </Row>
          <Row className="justify-content-center">
            <Col
              lg="4"
              className="d-flex justify-content-center align-items-start"
            >
              <Box
                as="img"
                width="100%"
                maxWidth="40ch"
                mx="auto"
                height="auto"
                src={dawidImage}
                alt="David Odrakiewicz"
              />
            </Col>
            <Col lg="8">
              <Text color="light" textAlign="justify">
                Przedsiębiorca, który w trakcie swojej kariery zawodowej
                koncentrował swoją działalność wokół doradztwa dla spółek z
                grupy start up oraz inwestycji kapitałowych. Początkowo
                doświadczenie zdobywał jako Specjalista ds. Wycen w ramach BNP
                Securities Services, kontrolował wyceny aktywów funduszy
                inwestycyjnych oraz zobowiązań funduszy, a później jako Analityk
                Operacji Finansowych w Citibank International, weryfikował i
                potwierdzał z brokerami transakcje związane z obsługą papierów
                wartościowych. W latach 2016-2018 pracował jako Starszy Analityk
                w Goldman Sachs, gdzie odpowiadał za zapewnienie krytycznej
                wiedzy merytorycznej z zakresu inwestycji i podatków, dla
                inwestycji realizowanych w Europie. Był również prelegentem na
                uczelniach wyższych z zakresu ekonomii i finansów. Obecnie w
                ramach Blackcode Capital Sp. z o.o., gdzie pełni rolę Prezesa
                Zarządu skupia się na doradztwie biznesowym i inwestycjach
                kapitałowych.
              </Text>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  )
}

export default About
