import React from 'react'
import Hero from '../sections/landing7/Hero'
import Content from '../sections/landing7/Content'

import About from '../sections/landing7/About'

import PageWrapper from '../components/PageWrapper'

const IndexPage = () => {
  return (
    <>
      <PageWrapper headerDark footerDark>
        <Hero />
        {/* <Fact /> */}
        <Content />
        {/* <CaseStudies /> */}
        {/* <Reviews /> */}
        <About />

      </PageWrapper>
    </>
  )
}
export default IndexPage
