import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import { Title, Button, Section, Box, Text } from '../../components/Core'

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <Section bg="dark">
        <div className="pt-5"></div>
        <Container
          className="position-relative"
          css={`
            z-index: 10;
          `}
        >
          <Row>
            <Col md="12" lg="12" xl="10">
              <Box py={4}>
                <Title
                  my={4}
                  variant="hero"
                  color="light"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-once="true"
                  data-aos-delay="1000"
                >
                  Tworzymy rzeczywistość finansową
                </Title>
                <Text
                  color="light"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-once="true"
                  data-aos-delay="700"
                >
                  Doradzamy klientom w głównych aspektach prawnych i finansowych
                  w zakresie funkcjonowania przedsiębiorstw na rynkach polskich
                  i zagranicznych.
                </Text>
                
              </Box>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  )
}

export default Hero
