import React, { useState } from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'

import { Title, Section, Box, Text, List } from '../../components/Core'
import { device } from '../../utils'

const SectionStyled = styled(Section)``

const ContentWidget = styled(Box)`
  border-radius: 10px;
  border: 1px solid #2d2d30;
  background-color: ${({ theme }) => theme.colors.dark};
  padding: 20px 0;
  padding-left: 28px;
  padding-right: 55px;
  min-width: 100%;
  width: 100%;
  min-height: 90px;
  display: flex;
  align-items: center;
  transition: 0.4s;
  cursor: pointer;

  @media ${device.xl} {
    width: 360px;
    min-width: 360px;
  }
  @media ${device.md} {
    min-height: 89px;
  }

  i {
    font-size: 30px;
    color: #fff;
    position: absolute;
    right: 0;
    margin-right: 30px;
    opacity: 0;
    transition: 0.4s;
  }

  &:hover {
    box-shadow: 0 52px 54px rgba(25, 25, 27, 0.3);
    border-radius: 10px;
    background-color: ${({ theme }) => theme.colors.secondary};
  }
  &:hover i {
    transform: translateX(10px);
    opacity: 1;
  }
`

const ContentCard = ({ children = '', onClick }) => {
  return (
    <ContentWidget onClick={onClick}>
      <div className="d-flex align-items-center">
        <Title color="light" variant="card" mb={0}>
          {children}
        </Title>
      </div>

      <i className="fas fa-chevron-circle-right"></i>
    </ContentWidget>
  )
}

const Doradztwo = () => (
  <>
    Zajmujemy się konsultingiem związanym z doradztwem strategicznym dla przedsiębiorstw
    już istniejących jak i nowopowstałych. Wybór odpowiedniej strategii
    biznesowej stanowi podstawę dla działania oraz spaja wszelkie obszary
    działalności przedsiębiorstwa. Do naszych podstawowych kompetencji należy:
    <List color="light" mt={3}>
      <li>przygotowywanie i opracowywanie biznesowych analiz strategicznych</li>
      <li>wsparcie przedsiębiorstw w realizacji opracowanych strategii</li>
      <li>analiza i określenie pozycji konkurencyjnej przedsiębiorstw</li>
      <li>
        rekomendacje dotyczące decyzji o charakterze kluczowym dla
        funkcjonowaniaprzedsiębiorstw
      </li>
      <li>
        opracowanie i wdrażanie koncepcji zmian organizacyjnych w
        przedsiębiorstwie
      </li>
      <li>opracowanie i realizację strategii inwestycyjnych</li>
      <li>zarządzanie ryzykiem</li>
      <li>konsulting związany z inwestowaniem w nowe technologie</li>
      <li>analiza i usprawnianie procesów biznesowych w przedsiębiorstwach</li>
    </List>
  </>
)

const Finanse = () => (
  <>
    W ramach konsultingu Blackcode Capital skupia się na
    wsparciu przedsiębiorstw w funkcjonowaniu na rynkach biznesowych. Do usług najczęściej oferowanych naszym klientom należą:
    <List color="light" mt={3}>
      <li>
        wsparcie w przygotowywaniu umów strategicznych, w tym umów handlowych i
        inwestycyjnych
      </li>
      <li>
        realizacja uprawnień korporacyjnych, w tym dopłaty, umorzenia,
        wyłączenia wspólników, zaskarżanie uchwał
      </li>
      <li>reprezentowanie w sporach prawnych o charakterze biznesowym</li>
      <li>wybór formy prowadzenia działalności gospodarczej</li>
      <li>finansowe due dilligence w due dilligence</li>
      <li>
        wdrażania i opracowywanie regulaminów i innych dokumentów w zakresie
        ładu korporacyjnego
      </li>
    </List>
  </>
)

const Kapital = () => (
  <>
    Wieloletnie doświadczenie w funkcjonowaniu na rynku kapitałowym umożliwia
    nam realizację realizację następujących usług:
    <List color="light" mt={3}>
      <li>
        wsparcie prawnego w prywatnych oraz publicznych procesach emisji akcji i
        innych instrumentów finansowych
      </li>
      <li>
        wsparcie negocjacyjne oraz prawne w sporach z zakresu transakcji na
        rynkach kapitałowych
      </li>
      <li>
        analiza regulaminów i działania instytucji i przedsiębiorstw na rynku
        finansowym
      </li>
    </List>
  </>
)

const Oferta = ({ which }) => {
  const Wrapper = ({ children }) => (
    <Row className="justify-content-center px-4">
      <Text color="light">
        {children}
      </Text>
    </Row>
  )
  switch (which) {
    case 'doradztwo':
      return (
        <Wrapper>
          <Doradztwo />
        </Wrapper>
      )
    case 'finanse':
      return (
        <Wrapper>
          <Finanse />
        </Wrapper>
      )
    case 'kapital':
      return (
        <Wrapper>
          <Kapital />
        </Wrapper>
      )
    case 'hide':
    default:
      return <Wrapper />
  }
}

const Content = () => {
  const [currentCard, setCard] = useState('hide')

  const toggleCard = cardName => () =>
    setCard(currentCard === cardName ? 'hide' : cardName)

  return (
    <>
      {/* <!-- Content section --> */}
      <SectionStyled bg="dark" id="oferta">
        <Container>
          <Row className="justify-content-center mb-5">
            <Col lg="10">
              <div className="text-center">
                <Title color="light">Zakres działalności</Title>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col
              lg="4"
              md="6"
              className="mb-4"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-once="true"
            >
              <ContentCard onClick={toggleCard('doradztwo')}>
                Doradztwo strategiczne
              </ContentCard>
            </Col>
            <Col
              lg="4"
              md="6"
              className="mb-4"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-once="true"
              data-aos-delay="200"
            >
              <ContentCard onClick={toggleCard('finanse')}>
                Prawo finansowe
              </ContentCard>
            </Col>
            <Col
              lg="4"
              md="6"
              className="mb-4"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-once="true"
              data-aos-delay="400"
            >
              <ContentCard onClick={toggleCard('kapital')}>
                Doradztwo dotyczące rynków kapitałowych
              </ContentCard>
            </Col>
          </Row>
          <Oferta which={currentCard} />
        </Container>
      </SectionStyled>
    </>
  )
}

export default Content
